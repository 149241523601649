import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import About from '../views/About.vue'
import ProgrammingProject from '../views/ProgrammingProject.vue'
import Interests from '../views/Interests.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    alias: '/about',
    name: 'About',
    component:About
  },
  {
    path: '/programmingProjects',
    name: 'ProgrammingProjects',
    component: ProgrammingProject
  },
  {
    path: '/interests',
    name: 'Interests',
    component: Interests
  },
]

const router = new VueRouter({
  routes
})

export default router
