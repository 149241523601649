




















































import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Interests extends Vue {
  data() {
    return {
      cycle: false,
      slides: [
        {
          src: require('../assets/digital_clock_closeup.png'),
          text: "Im ersten Auftrag durfte ich eine digitale Uhr mit html, css und javascript gestalten. Am Anfang hat mir Jan (der Projektleiter) die wichtigsten Einzelheiten erklärt z.B. wieman den Projekt stukturiert oder ein commit auf git.swisscom pusht. Danach habe ich michauf w3school schlau genacht und angefangen auszuprobieren. Das Projekt wurde mit IntelliJ IDEA initiallisiert.",
          title: "Digital Clock",
          subtitle: "Projekt TalentsSquad",
          icon: "mdi-alarm"
        },
        {
          src: require('../assets/animal_task.png'),
          text: "Bei diesem Auftrag durfte ich mich mit den Grundlagen von Angular auseinandersetzten. Ich habe gelernt, dass Angular aus verschiedenen Komponenten besteht, die eine bestimmte Funktion einer Webseite ausführen und ineinander hierarchisch verschachtelt sind und dass Services injiziert werden können. Bei diesem Task habe ich einen Service für die CRUD Operationen erstellt. Dieser wurde danach in die jeweiligen Komponenten injizier z.B. zu add-animal.component.ts",
          title: "Angular Animal Farm (Frontend)",
          subtitle: "Projekt TalentsSquad",
          icon: "mdi-barn"
        },
        {
          src: require('../assets/weather_widget_task.png'),
          text: "Im letzten Auftrag bei TSQ durfte ich an einem Weather Widget herumbasteln. Das Widget zeigt das Wetter vom aktuellen oder einem anderen beliebigen Standort an und gibt eine Wetterprognose für die nächsten drei Tage. Die Wetterdaten wurden von der opernweather-api gefetcht. Für dieses Projekt habe ich wieder das Web-Framework Angular verwendet.",
          title: " Angular Weather Widget (Frontend)",
          subtitle: "Projekt TalentsSquad",
          icon: "mdi-cloud"
        },
        {
          src: require('../assets/crud-app-nest-js_backend.png'),
          text: "Beim Apps Team durfte ich nach einer zweiwöchigen Backend-Trainingsphase auf KhanAcademy damit anfangen eine CRUD-App zu implementieren. Ziel dieser Übung war es eine REST-API zu erstellen, die eine Liste von Büchern zurückgibt und man neue Bücher zu dieser Liste hinzufügen und Bestehende bearbeiten oderlöschen kann. Die Applikation wurde durch eine Usertabelle mit unterschiedlichen Rollenberechtigungen erweitert, sodass nur noch Bücher abgefragt werden können, wenn der User sich einloggt hat. Für die Umsetzung dieses Auftrags verwendete ich NestJs als Framework mit TypeORM für die Anbindung an mariadb in Docker.",
          title: "NestJs CRUD-App (Backend)",
          subtitle: "Projekt Apps Team",
          icon: "mdi-bookshelf"
        },
        {
          src: require('../assets/sofia-homepage.png'),
          text: "Beim Apps Team durfte ich nach einer zweiwöchigen Backend-Trainingsphase auf KhanAcademy damit anfangen eine CRUD-App zu implementieren. Ziel dieser Übung war es eine REST-API zu erstellen, die eine Liste von Büchern zurückgibt und man neue Bücher zu dieser Liste hinzufügen und Bestehende bearbeiten oderlöschen kann. Die Applikation wurde durch eine Usertabelle mit unterschiedlichen Rollenberechtigungen erweitert, sodass nur noch Bücher abgefragt werden können, wenn der User sich einloggt hat. Für die Umsetzung dieses Auftrags verwendete ich NestJs als Framework mit TypeORM für die Anbindung an mariadb in Docker.",
          title: "SoFIA (Solution Front Interaction Application)",
          subtitle: "Projekt Apps Team",
          icon: "mdi-account-search"
        },
        {
          src: require('../assets/memory_game.png'),
          text: "In letzten Projekt vom Apps Team lernte ich React und implementierte dazu eine Memory-App inklusive Anbindung an eine Rest-API.",
          title: "Memory Card Game",
          subtitle: "Projekt Apps Team",
          icon: "mdi-gamepad-variant"
        },
        {
          src: require('../assets/crud-app-vue-js_backend_frontend.png'),
          text: "Am Anfang des neuen Projekt im Nex-CC lernte ich, wie man eine FUll Stack CRUD App baut. Für Back-End-Server verwendete ich  Spring Boot mit Spring Web MVC für den REST-Controller und Spring Data JPA für die Interaktion mit der eingebetteten Datenbank (H2-Datenbank). Die Frontend-Seite wurde mit Vue & Vue Route erstellt.",
          title: "Tutorial CRUD-App",
          subtitle: "Projekt Nex-CC",
          icon: "mdi-clipboard-list"
        },
        {
          src: require('../assets/interview-planning.png'),
          text: "Aktuell befasse ich mich mit dem Interview Planning Tool und verbessere die Stabilität und die Geschwindigkeit der Software und implementiere neue Features.",
          title: "Interview Planning  Tool",
          subtitle: "Projekt Nex-CC",
          icon: "mdi-account-voice"
        },
      ],
    }
  }
}
