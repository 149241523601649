import { render, staticRenderFns } from "./ProgrammingProject.vue?vue&type=template&id=0924b3ca&scoped=true&"
import script from "./ProgrammingProject.vue?vue&type=script&lang=ts&"
export * from "./ProgrammingProject.vue?vue&type=script&lang=ts&"
import style0 from "./ProgrammingProject.vue?vue&type=style&index=0&id=0924b3ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0924b3ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
installComponents(component, {VCard,VCarousel,VCarouselItem,VIcon,VImg,VList,VListItem,VListItemActionText,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VSystemBar})
