






























































































import Vue from 'vue'
import {Component, Prop} from "vue-property-decorator";

@Component
export default class LanguageSkills extends Vue {
  @Prop() private title!: string;

  data() {
    return {
      languages: [
        {
          language: 'Deutsch (Muttersprache)',
          rating: 99,
          color: "#0d4252",
          bgColor: "#5198b4",
        },
        {
          language: 'Französisch',
          rating: 75,
          color: "#0d4252",
          bgColor: "#5198b4",
        },
        {
          language: 'English (Fliessend)',
          rating: 80,
          color: "#0d4252",
          bgColor: "#5198b4",
        },
      ],
      rating: 6,
    }
  }
}
