









import { Component, Vue } from 'vue-property-decorator';
import PersonalData from '@/components/PersonalData.vue'; // @ is an alias to /src
import LanguageSkills from '@/components/LanguageSkills.vue';
import Timeline from "@/components/Timeline.vue";
import ITSkills from "@/components/ITSkills.vue";
@Component({
  components: {
    PersonalData,
    LanguageSkills,
    Timeline,
    ITSkills,
  },
})
export default class Home extends Vue {}
