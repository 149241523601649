











































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class Timeline extends Vue {
  @Prop() private title!: string;

  data() {
    return {
      timelineInputs: [
        {
          icon: 'mdi-book-variant',
          color: "#0d4252",
          year: '2018',
          title: "Matura",
          description: "Kantonsschule Wettingen",
        }, {
          icon: 'mdi-account-wrench',
          color: "#0d4252",
          year: '2019',
          title: "Zivildienstleistender",
          description: "Integra Wohlen und SAH Zürich",
        }, {
          icon: 'mdi-face-agent',
          color: "#0d4252",
          year: '2020',
          title: "Callagent",
          description: "Cardcenter in Glattbrugg",
        }, {
          icon: 'mdi-account-wrench',
          color: "#1b6079",
          year: '',
          title: "Zivildienstleistender",
          description: "Altersheim Veltheim",
        }, {
          icon: 'mdi-school-outline',
          color: "#1b6079",
          year: '2020',
          title: "Lernender Applikationsentwicklung",
          description: "Projekt TalentSquad",
        }, {
          icon: 'mdi-school-outline',
          color: "#23687f",
          year: '2021',
          title: "Lernender Applikationsentwicklung",
          description: "Projekt DIP",
        }, {
          icon: 'mdi-school-outline',
          color: "#23687f",
          year: '',
          title: "Lernender Applikationsentwicklung",
          description: "Projekt Apps Team",
        }, {
          icon: 'mdi-school',
          color: "#337e99",
          year: '2022',
          title: "Lernender Applikationsentwicklung",
          description: "Projekt NEX-CC",
        },
      ],
    }
  }
}
