




























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ITSkills extends Vue {
  @Prop() private title!: string;

  data() {
    return {
      itSkills: [
        {
          value: 60,
          name: "CSS",
          color: "#337e99",
        }, {
          value: 60,
          name: "HTML",
          color: "#337e99",
        }, {
          value: 60,
          name: "JavaScript\n Typescript",
          color: "#337e99",
        }, {
          value: 75,
          name: "Vue",
          color: "#337e99",
        }, {
          value: 30,
          name: "PHP",
          color: "#1b6079",
        }, {
          value: 60,
          name: "NestJS",
          color: "#1b6079",
        }, {
          value: 50,
          name: "Java",
          color: "#1b6079",
        }, {
          value: 60,
          name: "MySQL",
          color: "#1b6079",
        }, {
          value: 50,
          name: "Docker",
          color: "#0d4252",
        }, {
          value: 70,
          name: "React",
          color: "#0d4252",
        }, {
          value: 50,
          name: "Postman",
          color: "#0d4252",
        }, {
          value: 70,
          name: "Jira",
          color: "#0d4252",
        },
      ],
    }
  }
}
