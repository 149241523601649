




















































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class PersonalData extends Vue {
  @Prop() private title!: string;

  data() {
    return {
      personalData: [
        {
          icon: "mdi-account-circle",
          name: 'Vorname / Nachname',
          info: "Marco Karpf",
          color: "#337e99",
        },
        {
          icon: "mdi-map-marker",
          name: 'Adresse',
          info: "Brüelstrasse 44, 8957 Spreitenbach",
          color: "#23687f"
        },
        {
          icon: "mdi-cake",
          name: 'Geburtsdatum',
          info: "05.August 1997",
          color: "#1b6079"
        },
        {
          icon: "mdi-cellphone-basic",
          name: 'Telefon',
          info: "+41798450081",
          color: "#0d4252"
        },
        {
          icon: "mdi-email",
          name: 'E-Mail',
          info: "marco.karpf@swisscom.com",
          color: "#042834"
        },
      ],
    }
  }
}
